@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  color: $grey850;
  font-size: 14px;
  line-height: 16px;

  @include to(2) {
    margin: 20px 30px;
  }

  @include from(3) {
    margin: 30px;
  }
}

.dates {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 400;
}

.heading {
  font-size: 26px;
  font-weight: 600;

  @include to(2) {
    line-height: 1.2em;
  }

  @include from(3) {
    line-height: 1.6em;
    white-space: nowrap;
  }
}

.subheading {
  display: flex;
  flex-direction: row;
  font-weight: 600;

  @include to(2) {
    margin-top: 10px;
  }
}

.subheadingContainer {
  margin-right: 2rem;
}

.subheadingValue {
  margin-right: 0.25rem;
}

.subheadingLabel {
  font-weight: 400;
}

.gameDescription {
  margin-top: 20px;
  color: $grey850;
  font-size: 16px;
  line-height: 24px;
}

.joinGameButton {
  width: unset !important;
  max-width: 340px;
  margin-top: 20px;
  margin-right: unset !important;
  margin-left: unset !important;
  font-weight: 500;

  @include to(2) {
    align-self: center;
  }
}

.footnote {
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @include to(2) {
    align-self: center;
  }
}
