@import 'breakpoints.scss';
@import 'colors.scss';
@import './constants.module.scss';

.top {
  height: #{$cardTopHeight}px;
}

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: #{$cardWidth}px;
  height: #{$cardHeight}px;
  border-radius: 5px;
  background-color: $white;
  color: $grey900;

  &:hover,
  &:visited {
    color: $grey900;
  }
}

.image {
  width: 100%;
}

.imageContainer {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.category {
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  min-width: 60px;
  padding: 0.2em 0.5rem;
  border: 1px solid themeColor('waybetter', 'secondary');
  border-radius: 4px;
  background-color: themeColor('waybetter', 'secondary');
  color: $white;
  font-size: 10px;
  text-align: center;
}

.coach {
  display: flex;
  position: absolute;
  top: 75px;
  left: 12px;
  flex-direction: column;
  align-items: flex-start;
}

.coachName {
  width: 140px;
  margin-top: 5px;
  overflow: hidden;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coachAvatar {
  padding: 1px;
  background: $white;
}

.bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 12px;
}

.dates {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.title {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.numbersRow {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 21px;
  margin-right: 0.8rem;

  &:last-child {
    margin-right: 0;
  }
}

.itemValue {
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
}

.itemLabel {
  font-size: 11px;
  line-height: 12px;
}
