@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, $grey25, $grey50);

  @include to(2) {
    padding: 4rem 0;
    text-align: center;
  }

  @include from(3) {
    height: 529px;
  }
}

.headingsContainer {
  padding: 0 2rem;
}

.heading {
  display: block;
  margin-bottom: 1rem;
  font-weight: 600;

  @include to(2) {
    font-size: 23px;
  }

  @include from(3) {
    font-size: 26px;
  }
}

.subheading {
  font-weight: 400;

  @include to(2) {
    margin-bottom: 20px;
    font-size: 14px;
  }

  @include from(3) {
    margin-bottom: 0.5rem;
    font-size: 16px;
  }
}

.gameCardsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 49px;
}

.carouselDesktop {
  @include to(2) {
    display: none;
  }

  @include from(3) {
    display: flex;
    flex-direction: row;
    justify-items: center;
    width: 900px;
  }
}

.carouselMobile {
  @include to(2) {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 32px;
    justify-items: center;
  }

  @include from(3) {
    display: none;
  }
}
