@import 'breakpoints.scss';
@import 'utils.scss';

.container {
  @include to(2) {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  @include from(3) {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.heading {
  font-weight: 600;
  text-align: center;

  @include from(3) {
    margin-bottom: 60px;
    font-size: 48px;
  }

  @include to(2) {
    margin-bottom: 30px;
    line-height: 23px;
  }
}

.rules {
  padding: 0 30px;
}

.rule {
  @include markdownRemoveMargins;

  @include from(3) {
    font-size: 17px;
  }

  ul {
    margin-left: 1.5em;
    padding: 0;
  }
}

.ruleHeading {
  margin: 0;
}
