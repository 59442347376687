@import 'utils.scss';
@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;

  @include to(2) {
    flex: 1 1 auto;
    justify-content: center;
    padding: 0 20px;
  }

  @include from(3) {
    flex: 1 1 500px;
    padding: 0 70px;
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
    // NOTE: fixes IE11 bug where content size is not respected.
    @include to(2) {
      flex: 0 0 auto;
    }
  }
}

.headerLine.content {
  @include from(3) {
    margin-top: 25px;
  }

  &::before {
    content: '';
    width: 275px;
    height: 5px;

    @include from(3) {
      display: block;
    }
  }
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme} .content::before {
    background: $secondary;
  }

  .#{$theme} .markdown a {
    color: $secondary;
    text-decoration: none;
  }
}

.title {
  font-size: 48px;
  font-weight: 600;
  white-space: pre-line;
}

.headerLine .title {
  @include to(2) {
    margin-top: 50px;
  }

  @include from(3) {
    margin-top: 70px;
  }
}

.subtitle {
  color: $fontMedium;
  font-size: 18px;

  @include from(3) {
    margin-top: 10px;
  }
}

.markdown {
  @include markdownRemoveMargins;

  @include to(2) {
    margin-top: 50px;
  }

  @include from(3) {
    margin-top: 40px;
  }
}

.action {
  @include to(2) {
    align-self: center;
    margin-top: 50px;
  }

  @include from(3) {
    margin-top: 40px;
  }
}

.image {
  width: 100%;
  object-fit: cover;
  object-position: center top;

  @include to(2) {
    display: none;
  }

  @include from(4) {
    flex: 0 1 500px;
  }

  @include from(3) {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-height: 650px;
  }
}

.content .image {
  @include to(2) {
    display: block;
    margin-top: 50px;
  }

  @include from(3) {
    display: none;
  }
}

.left {
  @include to(2) {
    flex-direction: column-reverse;
  }

  @include from(3) {
    flex-direction: row;
  }
}

.right {
  @include to(2) {
    flex-direction: column-reverse;
  }

  @include from(3) {
    flex-direction: row-reverse;
  }
}
