@import 'breakpoints.scss';
@import 'colors.scss';

.container.container {
  display: block;
  align-self: stretch;
  text-align: center;

  @include from(3) {
    padding: 58px 0 69px;
  }

  @include to(2) {
    margin-bottom: 25px;
    background: transparent;
  }
}

.heading.heading {
  @include from(3) {
    color: $white;
  }
}

.borderRow.borderRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  @include from(3) {
    border: 0;
    background: transparent;
  }
}

.image {
  @include from(3) {
    max-width: 500px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 $grey800;
  }

  @include to(2) {
    max-width: 100%;
    margin: 0 auto;
  }
}

.content {
  max-width: 450px;
  text-align: center;

  @include to(2) {
    color: $fontColor;
  }
}
