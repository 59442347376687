.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  text-align: center;
}

.heading.heading {
  margin: 0 0 20px;
}

.message {
  width: 263px;
  margin: 0 0 20px;
}
