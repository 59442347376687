@import 'colors.scss';

.avatar {
  display: inline-block;
  padding: 2px;
  border-radius: 15px;
  background: linear-gradient(
    to bottom right,
    themeColor('waybetter', 'secondary'),
    $white
  );
}
