@import 'breakpoints.scss';

.topGuarantee {
  @include from(3) {
    margin-bottom: 45px;
  }
}

.description {
  @include from(3) {
    display: none;
  }
}

.bonus {
  margin: 0 auto 40px;
}

.host {
  @include to(1) {
    display: none;
  }
}

.gameGuides {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @include to(2) {
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    > :first-child {
      margin: 20px 0;
    }
  }

  > :first-child {
    margin-right: 12px;
  }
}
