@import 'breakpoints.scss';
@import 'colors.scss';

.row.row {
  @include from(3) {
    padding: 0;
  }
}

.container {
  display: flex;
  color: $fontColor;
}

.content {
  display: flex;
  flex-direction: column;

  @include from(3) {
    padding: 23px;
  }
}

.imageContainer {
  @include from(3) {
    position: relative;
    flex: 0 0 213px;
    height: 213px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
  }

  @include to(2) {
    flex: 0 0 80px;
    height: 80px;
    margin-right: 12px;
    border-radius: 5px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include from(3) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  @include to(2) {
    border-radius: 5px;
  }
}

.smallImage {
  @include from(3) {
    display: none;
  }
}

.largeImage {
  @include to(2) {
    display: none;
  }
}

.subtype {
  margin: 4px 0 0;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;

  @include from(3) {
    display: none;
  }
}

.displayCategoryParent {
  display: flex;
  justify-content: center;

  @include from(3) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px 0 0 15px;
  }

  .displayCategory {
    display: inline-block;
    margin: 0;
    padding: 2px 6px;
    border: 1px solid themeColor('waybetter', 'secondary');
    border-radius: 5px;
    color: themeColor('waybetter', 'secondary');
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;

    @include from(3) {
      background-color: themeColor('waybetter', 'secondary');
      color: $white;
    }
  }
}

.subtext {
  font-size: 14px;
  line-height: 20px;
}

.avatar {
  background: none;
}

.coachInfo {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  h3 {
    margin-left: 10px;
    font-size: 15px;
  }
}

%tagHeading {
  margin: 0 0 5px;
  font-style: italic;
  font-weight: 600;
}

.subtypeHeading {
  @extend %tagHeading;
}

.memberHeading {
  @extend %tagHeading;

  color: $membership;
}

.title {
  margin-bottom: 4px;
  line-height: 29px;

  @include from(3) {
    font-size: 28px;
  }

  @include to(2) {
    font-size: 24px;
  }
}

.description {
  margin: 0 0 10px;

  @include from(3) {
    font-size: 17px;
    line-height: 21px;
  }
}

.details {
  margin-bottom: 10px;
}

@each $subtype in 'kickstarter', 'transformer' {
  $color: themeColor('dietbet', $subtype);

  .#{$subtype} {
    .subtype {
      color: $color;
    }

    .subtypeHeading {
      color: $color;
    }
  }
}

.dates {
  margin-top: auto;
  margin-bottom: 7px;
}

.button.button {
  align-self: center;
  width: 168px;
  margin-right: 2px;
  margin-left: auto;
  white-space: nowrap;

  @include to(2) {
    display: none;
  }
}

.arrowIcon.arrowIcon {
  width: auto;
  height: 17px;
}
