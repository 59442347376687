@import 'breakpoints.scss';
@import 'colors.scss';

.ctaOfferContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 312px;
  min-height: 83px;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 18.5px 18.5px 18.5px 13.5px;
  border-radius: 5px;
  background-color: $grey50;
}

.col1 {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.heading {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.upfrontCost {
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
}

.strikeCost {
  margin-right: 10px;
  color: $grey300;
  text-decoration: line-through;
}

.col2 {
  margin-left: auto;
}

.joinGameButton {
  width: 98px !important;
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
  border-radius: 2px !important;
  background-color: themeColor('waybetter', 'secondary');
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.cta.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 125px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: themeColor('waybetter', 'secondary');
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 20px;
}
