@import 'utils.scss';
@import 'breakpoints.scss';

.row {
  flex-direction: column;
}

.container {
  position: relative;
  min-height: 500px;

  @include to(1) {
    padding: 45px 30px 0;
  }

  @include from(2) {
    padding: 45px 60px 0;
  }

  @include to(2) {
    text-align: center;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.appImage {
  position: absolute;
  right: 60px;
  bottom: 0;

  @include to(2) {
    display: none;
  }
}

.logo {
  width: 100%;
  max-width: 348px;
  height: 39px;
  margin-bottom: 0;
  object-fit: contain;

  @include to(1) {
    margin-bottom: 5px;
    object-position: center;
  }

  @include from(3) {
    object-position: left;
  }
}

.headline {
  margin-bottom: 15px;
  font-style: italic;
  font-weight: 600;
  text-transform: lowercase;

  @include to(1) {
    font-size: 55px;
  }

  @include to(2) {
    line-height: 1em;
  }

  @include from(2) {
    font-size: 96px;
  }

  &.small {
    @include from(3) {
      max-width: 550px;
      font-size: 42px;
    }
  }
}

.subline {
  @include markdownRemoveMargins;

  max-width: 550px;
  font-size: 18px;

  @include to(2) {
    margin: 0 auto 35px;
  }

  @include from(2) {
    margin-bottom: 20px;
    font-weight: 600;
    white-space: pre-line;
  }
}

.widget {
  margin-bottom: 35px;

  &.hubSendLink.hubSendLink {
    @include to(1) {
      display: none;
    }
  }
}

.widgetTitle {
  margin-bottom: 10px;
  font-size: 18px;
}

.widgetForm.widgetForm {
  max-width: 450px;
  height: 40px;
  font-size: 21px;

  @include to(2) {
    margin: 0 auto;
  }
}

.badges {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;

  @include to(1) {
    flex-direction: column;
    height: 110px;
  }

  @include from(2) {
    width: 350px;
  }
}

.badge {
  width: 170px;
  height: 50px;
}
