@import 'colors.scss';

.container.container {
  max-width: 323px;
}

.outerContainer {
  padding: 55px 26px 0;
}

.flexContainer {
  margin-bottom: 55px;
}

.header {
  font-size: 28px;
  text-align: center;
}

.subHeader {
  color: $grey700;
  font-size: 22px;
  text-align: center;
}

.submit {
  width: 100%;
  margin-top: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 23px;
}

.step {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.stepNumber {
  min-width: 25px;
}

.stepText {
  margin-left: 8px;
  color: $grey900;
  font-size: 18px;
}

.stepText.disabled {
  color: transparentize($grey900, 0.5);
}

.stepComplete {
  background-color: #0eac66;
}

.badges {
  display: flex;
  justify-content: space-evenly;
  margin-top: 31px;
}

.badge {
  position: relative;
  width: 152px;
}

.badge.disabled::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  border-radius: 4px;
  background-color: transparentize($black, 0.5);
}

@each $theme, $themeColors in $themes {
  $secondaryColor: map-get($themeColors, 'secondary');

  .#{$theme}.header {
    color: $secondaryColor;
  }

  .#{$theme}.stepNumber.disabled {
    background-color: transparentize($secondaryColor, 0.5);
  }
}
