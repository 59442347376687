.label {
  margin-top: 1rem;
  text-align: center;
}

.card.card {
  flex: 0 0 161px;
  width: 161px;
  margin: 0 0.4em;
  padding: 0;

  img {
    width: 65px;
    height: 65px;
    margin-bottom: 18px;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.875rem;
    white-space: normal;
  }
}
