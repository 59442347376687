@import 'breakpoints.scss';

.container {
  @include to(1) {
    padding: 100px 20px;
  }

  @include from(2) {
    padding: 150px 0;
  }
}

.title {
  margin: 0;
  text-align: center;
  white-space: pre-line;

  @include to(1) {
    font-size: 28px;
  }

  @include from(2) {
    margin-bottom: 120px;
    font-size: 38px;
  }
}

.games {
  justify-content: space-around;
}
