@import 'breakpoints.scss';
@import 'colors.scss';

.loading {
  margin: auto;
}

.joinGameButton.joinGameButton {
  width: 240px;
}

.container {
  background-color: $white;
}

.topRow {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;

  @include to(1) {
    flex-direction: column;
  }
}

.topRowSection {
  flex: 1;

  @include to(1) {
    width: 100%;
  }
}
