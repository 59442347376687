@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  // add reference position for overlay absolute positioning
  position: relative;
  flex-direction: column;
  width: 100%;

  @include to(1) {
    align-items: flex-start;
  }

  @include from(2) {
    align-items: flex-end;
    height: 100%;
  }
}

.imageContainerDynamic {
  @include to(1) {
    height: 270px;
  }

  @include from(2) {
    height: 100%;
    max-height: 700px;
  }
}

.imageContainerStatic {
  max-width: 700px;
  max-height: 400px;
}

.imageWrapper {
  @include to(1) {
    width: 100%;
  }
}

.image {
  object-fit: cover;
  object-position: top;
  width: 100%;

  @include to(1) {
    height: 100%;
  }

  @include from(2) {
    max-width: 1440px;
    height: 100%;
  }
}

.coachOverlay {
  display: flex;
  // Using relative positioning would leave a gap below the image
  // where the overlay would normally reside.
  position: absolute;
  bottom: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include to(2) {
    left: 20px;
  }

  @include from(3) {
    width: 100%;
  }
}

.coachInfo {
  display: block;
  margin-left: 1.2rem;
  color: $white;
  font-size: 18px;
  line-height: 20px;
}

.coachName {
  margin-bottom: 0.2rem;
  white-space: nowrap;
}

.categoryPill {
  display: inline-block;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  background-color: themeColor('waybetter', 'secondary');
  font-size: 11px;
  line-height: 11px;
}
