@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  min-height: 430px;
  padding: 0 0 25px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0 0 10px transparentize($black, 0.5);
  text-align: center;
  user-select: none;

  @include from(3) {
    flex: 0 0 290px;
    margin: 0 10px 20px;
  }

  @include to(2) {
    height: 450px;
    margin-right: 25px;
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

$imageHeight: 160px;

.image {
  flex: 0 0 160px;
  width: 100%;
  max-height: 160px;
  margin: 0 0 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
  object-position: center top;
}

.squareImage {
  flex: 0 0 140px;
  width: 140px;
  margin: 20px 0;
  border-radius: 5px;
}

.subTitle {
  margin: 0 0 15px;
  font-size: 15px;
  font-weight: 600;

  .kickstarter & {
    color: themeColor('dietbet', 'kickstarter');
  }

  .transformer & {
    color: themeColor('dietbet', 'transformer');
  }
}

.title {
  $lineHeight: 30px;

  max-height: $lineHeight * 3;
  margin: 0 0 15px;
  padding: 0 20px;
  overflow: hidden;
  color: $fontColor;
  font-size: 23px;
  font-weight: 600;
  line-height: $lineHeight;
}

.description {
  flex: 1 0;
  margin: 0 0 15px;
  padding: 0 20px;
  overflow: hidden;
  color: $fontColor;
  text-align: center;
  white-space: normal;

  @include to(2) {
    display: none;
  }
}

.stats {
  margin: auto 0 20px;
}

.cta {
  width: 64.32%;
}
