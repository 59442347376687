@import 'colors.scss';

// https://github.com/FormidableLabs/nuka-carousel/issues/693
*:focus {
  outline: none;
}

.prev {
  transform: translateX(-30px);
  font-size: 36pt;
  cursor: pointer;
}

.next {
  transform: translateX(30px);
  font-size: 36pt;
  cursor: pointer;
}
