@import 'colors.scss';

.resultItemMobile {
  // This height is necessary for the paging dots
  // to be positioned correctly.
  height: 140px;
  margin: auto;
}

.card.card {
  flex-flow: column nowrap;
  height: 270px;
  padding: 0;
}

.pagingDots {
  top: 0;
  padding: 0 0.3em;
  // !important is needed to override the carousel library
  // inline style (which sets it to black).
  // The active dot is this color, the others are this
  // color with reduced opacity.
  fill: $grey400 !important;

  // Make the buttons larger than the default. The spec
  // has the dots at 10px, but for some reason 11px was needed
  // to match.
  svg {
    width: 11px;
    height: 11px;
  }

  circle {
    r: 5px;
    cx: 6px;
    cy: 6px;
  }
}
