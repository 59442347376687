@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;

  @include to(2) {
    font-size: 14px;
  }
}

.unit,
.value {
  margin-right: 0.3rem;
}
