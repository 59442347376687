@import 'breakpoints.scss';

.joinGameButton.joinGameButton {
  width: 240px;
}

.membershipCtaRow {
  height: 480px;
}

/* Product Hero Styles */

.productHeroCtaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: unset;
  font-weight: 400;
}

.productHeroContainer {
  justify-content: center;
  margin-top: unset;
}

.productHeroContent {
  align-self: center;
}

.productHeroImageContainer {
  @include to(2) {
    height: 500px;
  }

  @include from(3) {
    height: 480px;
  }
}

.membershipValueProp {
  @include to(2) {
    margin-bottom: 69px;
    font-size: 26px;
    font-weight: 400;
    line-height: 34px;
  }

  @include from(3) {
    max-width: 570px;
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: 500;
    line-height: 45px;
  }
}
