@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.boxSelected {
  border: 2.5px solid themeColor('waybetter', 'primary');
}

.box,
.boxSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 312px;
  min-height: 83px;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 18.5px 18.5px 18.5px 13.5px;
  background-color: $grey50; // TODO

  &:last-child {
    margin-bottom: 0;
  }
}

.col1 {
  margin-right: 1em;
}

.radio {
  display: none;
}

.radio + label {
  display: inline-block;
  position: relative;
  padding: 10px;
  border: 2px solid $grey75;
  border-radius: 13px;
  background-color: $white;
}

.radio:checked + label {
  padding: 4.5px;
  border: 5.5px solid themeColor('waybetter', 'primary');
  border-radius: 10px;
}

.col2 {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.heading {
  margin-bottom: 13px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.upfrontCost {
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
}

.col3 {
  margin-left: auto;
}

.joinGameButton {
  width: 98px !important;
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
  border-radius: 2px !important;
  background-color: themeColor('waybetter', 'secondary');
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.joinGameButtonHidden {
  display: none !important;
}
