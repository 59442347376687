@import 'breakpoints.scss';
@import 'colors.scss';

.wrapper {
  display: inline-block;
  height: 100%;
}

.parent {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(
    180deg,
    rgb(93 92 92 / 0%) 0%,
    $grey900 100%
  );
}
