@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $magnolia;
  color: $grey850;
  font-size: 15px;
  line-height: 24px;

  @include to(2) {
    padding: 3.7rem 1.7rem;
    text-align: center;
  }

  @include from(3) {
    height: 400px;
    padding: 55px 15%;
  }
}

.header {
  font-size: 26px;
  line-height: 28px;

  @include from(3) {
    align-self: flex-start;
    line-height: 41px;
  }
}

.innerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include to(2) {
    flex-direction: column;
    align-items: center;
  }
}

.section {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 370px;
}

.description {
  h3 {
    // add margin to match the height of the
    // coach avatar section, so they line up
    margin: 20px 0;
  }
}

.body {
  line-height: 24px;
}

.equipmentNeeded {
  margin-top: 2rem;
}

.indented {
  @include from(3) {
    align-self: flex-start;
    margin-left: 25%;
  }
}

.coachInfo {
  display: flex;
  align-items: center;

  @include to(2) {
    flex-direction: column;
    margin: 20px;
  }

  @include from(3) {
    flex-direction: row;
  }

  h3 {
    margin-left: 20px;
  }
}

.avatar {
  @include to(2) {
    margin: 1rem 0;
  }
}

.footerHeadline {
  max-width: none;
  margin-top: 48px;

  @include to(2) {
    margin-top: 0;
    font-size: 35px;
  }
}
