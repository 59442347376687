@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 15%;
  background-color: $magnolia;
  color: $grey850;
  font-size: 15px;
  line-height: 24px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 370px;
}

.coachInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 30px;
    color: $grey850;
    font-size: 26px;
    line-height: 36px;
  }
}

.avatar {
  @include to(2) {
    margin: 1rem 0;
  }
}

.body {
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
