@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;

  @include to(2) {
    flex-direction: column-reverse;
  }
}

.image {
  @include to(2) {
    width: 100%;
    background: $grey25;
  }

  @include from(3) {
    max-width: 100%;
    height: 100%;
    min-height: 585px;
    object-fit: cover;
    object-position: center top;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 165px 50px 0;

  @include to(3) {
    flex: 0 0 auto;
  }

  @include from(4) {
    flex: 0 0 440px;
  }

  // NOTE: Fixes IE11 bug.
  // See https://github.com/philipwalton/flexbugs#flexbug-2
  > * {
    max-width: 100%;
  }
}

.title {
  margin-bottom: 55px;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}

$badgeMargin: 16px;

.badges {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 55px 0 120px - $badgeMargin;
}

.badge {
  width: 170px;
  height: 50px;
  margin-bottom: $badgeMargin;
}
