@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 300px;
  background-color: $white;
  color: $grey850;
  font-size: 16px;
  line-height: 24px;

  @include to(2) {
    flex-direction: column;
    padding: 3.7rem 1.7rem;
    text-align: center;
  }

  @include from(3) {
    padding: 7rem 10px 7rem 15%;
  }
}

.section {
  @include to(2) {
    margin: 0 1rem;
  }

  h2 {
    font-size: 26px;
    line-height: 28px;

    @include to(2) {
      margin-bottom: 2.5rem;
    }

    @include from(3) {
      margin-bottom: 3rem;
    }
  }
}

.left {
  flex: 2;

  @include to(2) {
    margin-bottom: 24px;
  }
}

.right {
  display: flex;
  flex: 3;
  flex-direction: column;

  @include from(3) {
    margin-left: 10%;
  }
}

.resultsDesktop {
  display: none;

  @include from(3) {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.resultsMobile {
  display: none;

  @include to(2) {
    display: block;
  }
}

.resultItemDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 161px;
  margin: 0 0.4em;
}
