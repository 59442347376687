@import 'colors.scss';
@import 'breakpoints.scss';

.title {
  margin-top: 80px;
  font-size: 32px;
  text-align: center;

  @include from(3) {
    margin-bottom: 15px;
  }

  @include to(2) {
    display: none;
  }
}

.subTitle {
  margin: 0 auto 40px;
  color: $grey600;
  font-size: 21px;
  font-style: italic;
  text-align: center;

  @include from(3) {
    max-width: 450px;
  }

  @include to(2) {
    display: none;
  }
}

.search {
  @include from(3) {
    display: flex;
    justify-content: flex-end;
  }

  @include to(2) {
    position: sticky;
    top: 0;
    width: 100%;
    height: 54px;
    padding: 10px;
    border-bottom: 1px solid $border;
  }
}

@each $theme, $themeColors in $themes {
  .#{$theme} {
    .search {
      @include to(2) {
        background-color: map-get($themeColors, 'background');
      }
    }
  }
}

.searchForm {
  @include to(2) {
    height: 32px;
    margin-bottom: 10px;
  }

  @include from(3) {
    margin-bottom: 20px;
  }
}

.searchInput.searchInput {
  font-size: 14px;
}

.noResultsMessage {
  text-align: center;
}

.list {
  max-width: 980px;
  margin: 0 auto;
}

.game {
  @include from(3) {
    margin-bottom: 20px;
  }

  @include to(2) {
    margin-bottom: 10px;
  }
}

.starting {
  color: $grey500;
  text-align: left;

  @include from(3) {
    margin: 40px 0 24px;
    font-size: 21px;
  }

  @include to(2) {
    margin: 24px 0 12px;
    padding: 0 12px;
    font-size: 17px;
  }
}
