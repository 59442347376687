@import 'colors.scss';
@import 'breakpoints.scss';

.container {
  max-width: 1084px; // 1024 + padding to align the content's left edge with the elements above
  margin: 1rem auto;
  overflow: hidden;
  background-color: #ffffff;
  justify-self: stretch;

  @include to(2) {
    margin: 0.75rem 10px;
  }
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.title {
  color: themeColor('waybetter', 'secondary');

  @include to(2) {
    max-width: 300px;
    font-size: 1.5rem;
  }

  @include from(3) {
    font-size: 1.5rem;
  }
}

.content {
  max-width: 75ch;
  max-height: var(--container-height);
  transition: max-height 0.3s ease-in-out;
  color: rgb(54 58 67);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
}

.collapsed .content {
  max-height: 0;
}

.showMore {
  position: relative;
  flex: 0 0 37px;
  width: 37px;
  height: 37px;
  border-radius: 0.25rem;
  background-color: themeColor('waybetter', 'medium');
  color: themeColor('waybetter', 'secondary');

  &::after {
    content: '';
    display: block;
    width: 60%;
    height: 3px;
    margin: auto;
    border-radius: 3px;
    background-color: themeColor('waybetter', 'secondary');
  }
}

.showMore::before {
  --width: 3px;

  content: '';
  display: block;
  position: absolute;
  top: 20%;
  left: calc(50% - calc(var(--width) / 2));
  width: var(--width);
  height: 60%;
  margin: 0 auto;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
  border-radius: var(--width);
  background-color: themeColor('waybetter', 'secondary');
}

.plusIcon::before {
  transform: rotate(0);
}

.collapsed {
  width: 100%;

  @include to(2) {
    width: auto;
    margin: 0.75rem 10px;
  }
}

.detail {
  margin: 0;
  padding: 0 2rem 2rem;
  transition: max-height 0.3s ease-out;
}

.expanded {
  width: 100%;

  @include to(2) {
    width: auto;
    margin: 0.75rem 10px;
  }
}
