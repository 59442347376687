@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: themeColor('waybetter', 'secondary');
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;

  @include to(2) {
    height: 40px;
    font-size: 14px;
  }
}

.label {
  margin-right: 0.3rem;
}
