@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @include from(3) {
    padding: 100px 20px 38px;
  }

  @include to(2) {
    padding: 30px 0;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 930px;
  margin-bottom: 30px;

  @include to(2) {
    display: none;
  }
}

.sliderContainer {
  width: 100%;

  @include from(3) {
    display: none;
  }
}

.dots {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 30px 0 20px;
}

.dot {
  outline: none;
  background: transparent;
  color: $white;
  font-size: 50px;
  line-height: unset;
}

.inactiveDot {
  opacity: 0.6;
}

.instructions {
  margin: 0 0 30px;
  text-align: center;
}

.cta {
  @include from(3) {
    width: 231px;
  }

  @include to(2) {
    width: 100%;
    max-width: 300px;
  }
}
