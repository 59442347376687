@import 'colors.scss';

.cta {
  width: 98px !important;
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
  border-radius: 2px !important;
  background-color: themeColor('waybetter', 'secondary');
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.ctaHidden {
  display: none !important;
}
