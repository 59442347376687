@import 'utils.scss';
@import 'breakpoints.scss';
@import 'colors.scss';

$cardWidth: 170px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include to(1) {
    @include markdownRemoveMargins;

    width: $cardWidth + 10px;
    margin-top: 100px;
  }

  @include from(2) {
    flex: 1 0 $cardWidth;
    margin-bottom: 24px;
  }
}

.image {
  height: 110px;
  margin-bottom: 25px;
}

.title {
  font-size: 30px;
  font-weight: normal;
  text-align: center;
}

.description {
  width: 100%;
  text-align: center;
  white-space: pre-line;

  @include to(1) {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.action {
  margin-top: auto;

  @include to(1) {
    font-weight: 600;
  }
}
