@import 'breakpoints.scss';
@import 'colors.scss';

.topOuter {
  flex-direction: column;
  align-items: center;

  .topInner {
    flex: 0 1;

    .logoContainer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.logo {
  width: 108px;
  height: 19px;
  object-fit: contain;
}

.gameImage {
  line-height: 0;
  max-width: 375px;

  img {
    width: 100%;
    min-height: 250px;
  }
}

.bottomOuter {
  .bottomInner {
    flex-direction: column;
    background-color: $white;
    padding: 30px 20px 80px;

    @include from(2) {
      border-radius: 10px;
      margin: 0 20px 20px;
      box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    }

    .coachAvatar {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      align-items: center;
    }

    .avatar {
      margin-bottom: 5px;
    }

    h4 {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      color: var(--color-cool-purple-700);
      padding: 0 40px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .downloadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--color-purple-400);
    border: 1px solid var(--color-purple-400);
    width: 100%;

    // stylelint-disable-next-line
    &:hover svg path {
      fill: $white;
    }
  }
}
